import React from 'react';

function App() {
  const value = 'World Test';
 

  return (<div>Hello {value}
            <div><b id='name'>...</b></div>
          </div>);
}

export default App;
